import React, { useState } from "react"
import ResetSetNewPassword from "./ResetSetNewPassword"
import { getChangePass, putChangePass } from "../../../api/user"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ResetPassFailToken from "./ResetPassFailToken"
import StyledLoading from '../../../components/loading/StyledLoading'
import { setError } from '../../../redux/ducks/snackbar'
import { connect } from 'react-redux'
import ResetSetNewPassSuccess from "./ResetSetNewPassSuccess"

const mapDispatchToProps = {
  setError
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

const ResetPassSetNewContainer = ({ setError, ...props }) => {
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [hashHasError, setHashHasError] = useState(false)
  const [disabledNav, setDisabledNav] = useState(false)
  const [successChange, setSuccessChange] = useState(false)

  const location = useLocation().search
  const token = new URLSearchParams(location).get('token')

  useEffect(() => {
    verifyResetHash()
  }, [token])

  const verifyResetHash = () => {
    window.localStorage.setItem('pw_reset_access_token', token)
    setIsLoading(true)
    getChangePass()
      .then(res => {
      })
      .catch(err => {
        setHashHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  const onSubmit = (e) => {
    e.preventDefault()
    setDisabledNav(true)
    putChangePass({ password: password })
      .then((res) => {
        setSuccessChange(true)
      })
      .catch((err) => {
        setError('There was an issue when trying to reset the password.')
      })
      .finally(() => {
        setDisabledNav(true)
      })
  }

  if (isLoading) return <StyledLoading />
  if (hashHasError) return <ResetPassFailToken />
  if (successChange) return <ResetSetNewPassSuccess />

  return (
    <ResetSetNewPassword
      disabledNav={disabledNav}
      password={password}
      onSubmit={onSubmit}
      setPassword={setPassword}
    />
  )
}

export default ResetPassSetNewContainer