import React, { useEffect, useState } from "react"
import './CreatorSetup.scss'
import PageContainer from "../../../components/page-container/PageContainer"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import CreatorSetupGeneralInfo from "../qr-editor-general/CreatorSetupGeneralInfo"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import { locations } from '../../../mocks/locations'
import { useParams, useLocation } from "react-router-dom"
import { qrEmpty } from "../../../mocks/qr-empty-template"
import { quiz } from "../../../mocks/qr-schema"
import StyledLoading from "../../../components/loading/StyledLoading"
import QRFieldsEditor from "../qr-creation/qr-fields-editor/QRFieldsEditor"
import QrFrameEditor from "../qr-frame/QrFrameEditor"
import { frameSchema } from "../../../mocks/frameSchema"
import QRPublish from "../qr-creation/qr-publish/QRPublish"
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { setSuccess, setError } from '../../../redux/ducks/snackbar'

const creatorSteps = ['General', 'QR Fields Editor', 'Frame Editor', 'Preview & Publish']

const CreatorSetup = ({ setSuccess, setError, ...props }) => {
  const [qrTemplate, setQrTemplate] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [qrQuiz, setQrQuiz] = useState(null)
  const [currentProgress, setCurrentProgress] = useState(0)
  const [qrFrameContent, setQrFrameContent] = useState(frameSchema)
  const [isPublished, setIsPublished] = useState(false)
  const qrId = useParams().id
  const navigate = useNavigate()

  useEffect(() => {
    if (qrId === 'new') {
      setQrQuiz(quiz)
    }
    else {
      setQrQuiz(quiz)
    }
    setIsLoading(false)
  }, [qrId])

  const setTemplate = (val) => {
    setQrTemplate(val)
    val === 0 && setQrQuiz(quiz)
    val === 1 && setQrQuiz(qrEmpty)
  }

  const publishSchemas = () => {
    setIsPublished(true)
    setSuccess('QR Code added successfully!')
    navigate('/creator/')
  }

  //const generalDisabled = qrName.length === 0 || qrLocation === ''
  if (isLoading) return <StyledLoading />

  return (
    <PageContainer className='creator-setup'>
      {currentProgress === 0 && <CreatorSetupGeneralInfo
        qrId={qrId}
        isEditing={qrId !== 'new'}
        quiz={qrQuiz}
        locations={locations}
        qrTemplate={qrTemplate}
        setQrTemplate={setTemplate}
      />}
      {currentProgress === 1 && <QRFieldsEditor
        quiz={qrQuiz}
        setQuiz={setQrQuiz}
      />}
      {currentProgress === 2 && <QrFrameEditor
        qrFrameContent={qrFrameContent}
        setQrFrameContent={setQrFrameContent}
      />}
      {currentProgress === 3 && <QRPublish
        qrSchema={qrQuiz}
        frameSchema={qrFrameContent}
      />}
      <div className="creator-setup-nav">
        <SecondaryButton
          disabled={currentProgress <= 0}
          onClick={() => { setCurrentProgress(currentProgress - 1) }}
        >
          Back
        </SecondaryButton>
        {currentProgress < creatorSteps.length - 1 &&
          <PrimaryButton
            onClick={() => { setCurrentProgress(currentProgress + 1) }}
          >
            Next
          </PrimaryButton>}
        {currentProgress === creatorSteps.length - 1 &&
          <PrimaryButton
            onClick={publishSchemas}
          >
            Publish
          </PrimaryButton>}
      </div>
    </PageContainer >
  )
}

const mapDispatchToProps = {
  setError,
  setSuccess
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

export default connectToStore(CreatorSetup)