export const edenProduct = {
  "id": 1,
  "name": 'eden general product',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#ffffff',
      "color": "#1b7f41",
      "accentColor": "#3d0a06",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/OperationEden.jpg'
          },
          {
            "id": 2,
            "type": "text",
            "style": {
              "fontSize": '14px',
            },
            "label": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet nulla facilisi morbi tempus. Congue quisque egestas diam in arcu cursus euismod:\n
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed`
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Your email address:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Your comment: ",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Your rating: ",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - mandatory fields",
          },
          {
            "id": 8,
            "type": "navigation",
            "style": {
              "btnColor": '#36a2d0',
            },
            "label": "Send"
          }
        ]
      }
    ]
  }
}