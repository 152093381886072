export const quiz = {
  "id": 1,
  "name": 'this cool qr name',
  "description": 'some description',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#2F323A',
      "color": '#F4F4F4',
      "fontSize": '14px',
      "accentColor": "#7C6EC4",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": "",
          },
          {
            "id": 3,
            "type": "text",
            "maxLength": 300,
            "label": "Example of a text field. Here you can type a lot of text. You have 300 characters!",

          },
          {
            "id": 4,
            "type": "email",
            "required": true,
            "label": "Email label",

          },
          {
            "id": 5,
            "type": "name",
            "label": "Name label",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Comment label",

          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Rating label",
            "max": 10,
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Submit"
          }
        ]
      }
    ]
  }
}