import React from "react"
import { activateAccount, requestActivationLink } from "../../../api/user"
import { useState } from "react"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import RegisterActivationError from "./RegisterActivationError"
import RegisterActivationSuccess from "./RegisterActivationSuccess"
import { setError, setSuccess } from '../../../redux/ducks/snackbar'
import { connect } from 'react-redux'

const mapDispatchToProps = {
  setError,
  setSuccess
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

const RegisterActivationContainer = ({ setError, setSuccess, ...props }) => {
  const [hasError, setHasError] = useState(false)
  const [errorType, setErrorType] = useState()
  const location = decodeURIComponent(useLocation().search)
  const params = new URLSearchParams(location).get('token')
  const email = new URLSearchParams(location).get('e')

  useEffect(() => {
    activateAcc()
  }, [location])

  const resendActivation = () => {
    requestActivationLink({ email: email })
      .then((res) => {
        setSuccess('Email sent successfully.')
      })
      .catch((err) => {
        if (err.response.status === 500) {
          setError('The email could not be sent. \nServer error, please try again later.')
        }
        else {
          setError(`The email could not be sent.\n${Object.values(err.response.data)}`)
        }
      })
  }

  const activateAcc = () => {
    window.localStorage.setItem('activate_account_token', params)
    activateAccount()
      .then((res) => {
      })
      .catch((err) => {
        setHasError(true)
        if (err.response.status === 400) {
          setErrorType(400)
        } else if (err.response.data.detail) {
          setErrorType(401)
        } else {
          setErrorType(404)
        }
      })
  }

  if (hasError) return <RegisterActivationError
    errorType={errorType}
    resendActivation={resendActivation}
    email={email}
  />

  return (
    <RegisterActivationSuccess />
  )
}

export default connectToStore(RegisterActivationContainer)