import axios from 'axios'
import { PROXY_ADDRESS } from './config'

const axs = axios.create({
  baseURL: PROXY_ADDRESS
})


const bearerToken = window.localStorage.getItem('jwt_access_token')
const activationToken = window.localStorage.getItem('activate_account_token')
const resetPassToken = window.localStorage.getItem('pw_reset_access_token')

const axsUse = (token) => {
  axs.interceptors.request.use(
    config => {
      if (token) {
        config.headers.Authorization = token
      } else {
        delete axs.defaults.headers.common.Authorization
      }
      return config
    },
    null
  )

  axs.interceptors.response.use(
    (res) => {
      // successfull response, nothing to do here
      return Promise.resolve(res)
    },
    (err) => {
      // don't know if there's a use case for now
      return Promise.reject(err)
    }
  )
  return axs
}

export const createAccount = (data) => {
  return axsUse().post(`/user/register/`, data)
}

export const activateAccount = () => {
  return axsUse(`Token ${activationToken}`).get(`/user/activate_account/`)
}

export const getChangePass = () => {
  return axsUse(`Token ${resetPassToken}`).get(`/user/change_pass/`)
}

export const putChangePass = (data) => {
  return axsUse(`Token ${resetPassToken}`).put(`/user/change_pass/`, data)
}

export const recoverAcc = (data) => {
  return axsUse(`Bearer ${bearerToken}`).post(`/user/recover/`, data)
}

export const login = (data) => {
  return axsUse().post(`/user/login/`, data)
}

export const verifyUserToken = (data) => {
  return axsUse(`Bearer ${bearerToken}`).post(`/user/api/token/verify/`, data)
}

export const refreshUserToken = (data) => {
  return axsUse(`Bearer ${bearerToken}`).post(`/user/api/token/refresh/`, data)
}

export const requestActivationLink = (data) => {
  return axsUse().post(`/user/request_activation_link/`, data)
}

export default axs