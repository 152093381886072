export const locations = [
  {
    id: 1,
    name: 'Kinda long location name to see if it fits but within some parameters',
    address: 'Some address for the location 1',
    company: 1,
    availableCodes: 5
  },
  {
    id: 2,
    name: 'Location 2',
    address: 'Some address for the location 2',
    company: 1,
    availableCodes: 3
  },
  {
    id: 3,
    name: 'Location 3',
    address: 'Some address for the location 3',
    company: 1,
    availableCodes: 3
  },
  {
    id: 4,
    name: 'Location 4',
    address: 'Some address for the location 3',
    company: 2,
    availableCodes: 1
  },
]



export const emptyLocation = {
  name: '',
  address: '',
  company: ''
}
