export const qrDefault = {
  "name": '',
  "location": '',
  "schema": {
    "style": {
      "backgroundColor": '#F6F1F1',
      "color": "#146C94",
      "fontSize": '14px',
      "accentColor": "#BE5A83",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "navigation",
            "label": "Submit"
          },
          {
            "id": 2,
            "type": "image",
            "image": "",
          },
          {
            "id": 3,
            "type": "text",
            "label": "Editable text field"
          },
          {
            "id": 4,
            "type": "email",
            "required": "true",
            "label": "Editable email field",
          },
          {
            "id": 5,
            "type": "name",
            "label": "Editable name field",
          },
          {
            "id": 6,
            "type": "comment",
            "required": "true",
            "label": "Editable comment field",
          },
          {
            "id": 7,
            "type": "rating",
            "required": "true",
            "label": "Editable rating field",
            "max": 10,
          },
        ]
      }
    ]
  }
}

export const qrEmpty = {
  "name": '',
  "location": '',
  "schema": {
    "style": {
      "backgroundColor": '#F6F1F1',
      "color": "#146C94",
      "fontSize": '14px',
      "accentColor": "#BE5A83",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "navigation",
            "label": "Submit",
            "style": {
              "labelColor": '',
              "btnColor": '',
              "fontSize": '14px',
            }
          }
        ]
      }
    ]
  }
}