import React, { useState } from "react"
import Register from "./Register"
import RegisterSuccess from "./RegisterSuccess"
import { createAccount } from "../../api/user"
import { setError } from '../../redux/ducks/snackbar'
import { connect } from 'react-redux'

const RegisterContainer = ({ setError, ...props }) => {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [disableNav, setDisableNav] = useState(false)
  const [userInfo, setUserInfo] = useState({
    username: '',
    email: '',
    password: ''
  })

  // A1bcd@333zz
  const submitUserInfo = (e) => {
    e.preventDefault()
    setDisableNav(true)
    createAccount(userInfo)
      .then((res) => {
        setHasSubmitted(true)
      })
      .catch((err) => {
        const errorField = Object.keys(err.response.data)[0]
        const errorMsg = Object.values(err.response.data)[0]
        setError(`There was an issue when creating the account!\n${errorField}: ${errorMsg}`)
      })
      .finally(() => {
        setDisableNav(false)
      })
  }

  return (
    <>
      {hasSubmitted ?
        <RegisterSuccess />
        :
        <Register
          userInfo={userInfo}
          disableNav={disableNav}
          setUserInfo={setUserInfo}
          setHasSubmitted={submitUserInfo}
        />}
    </>
  )
}

const mapDispatchToProps = {
  setError,
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

export default connectToStore(RegisterContainer)